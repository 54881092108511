.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
}


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-top : 20px;
}

.Start-advice {
  font-size: calc(5px + 1.5vmin);
  margin-bottom: 20px;
  color: grey;
}

.Cell-advice {
  font-size: 30px;
}
.Advice {
  transition-property: transform;
  transition-duration: 100ms;
  transform: scale(1);
}

@keyframes bounce {
  to { transform: scale(1.3); }
}